import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import useJwt from '@/auth/jwt/useJwt'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useIndicateList() {
  // Use toast
  const toast = useToast()

  const refIndicateListTable = ref(null)

  const today = new Date()
  const todayLocaleString = today.toLocaleString()
  const ninetyDaysAgo = new Date()
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90)
  const ninetyDaysAgoLocaleString = ninetyDaysAgo.toLocaleString()

  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'stage',
    //   label: '',
    //   icon: 'TrendingUpIcon',
    //   sortable: true,
    // },
    {
      key: 'coupon',
      label: 'Cupom',
      sortable: true,
    },
    {
      key: 'indicated',
      label: 'Indicado',
    },
    {
      key: 'indicatorName',
      label: 'Indicador',
      sortable: true,
    },
    {
      key: 'campaignName',
      label: 'Campanha',
      sortable: true,
    },
    { key: 'dates', label: 'Datas', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions', label: 'Validar' },
    { key: 'ver', label: 'Ver' },
  ]
  const currentCouponDetailsToken = ref(null)
  const perPage = ref(25)
  const totalIndicates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  // const searchQuery = ref(null)
  const searchQuery = ref(store.state.filters.indicationsFilter.searchQuery)
  const sortBy = ref('dates')
  const isSortDirDesc = ref(false)
  const stageFilter = ref(null)
  // const statusFilter = ref(null)
  const statusFilter = ref(store.state.filters.indicationsFilter.statusFilter)
  const filterDatesFilter = ref(null)
  // const campaignsFilter = ref(null)
  const campaignsFilter = ref(store.state.filters.indicationsFilter.campaignsFilter)
  const campaignsList = ref([])
  // const periodDateFilter = ref(null)
  const periodDateFilter = ref(store.state.filters.indicationsFilter.periodDateFilter)
  // const singleDateFilter = ref(null)
  const singleDateFilter = ref(store.state.filters.indicationsFilter.singleDateFilter)
  // const isSingleDate = ref(false)
  const isSingleDate = ref(store.state.filters.indicationsFilter.isSingleDate)
  const endDate = ref(
    `${todayLocaleString.slice(6, 10)}-${todayLocaleString.slice(3, 5)}-${todayLocaleString.slice(0, 2)}`,
  )

  const initDate = ref(
    `${ninetyDaysAgoLocaleString.slice(6, 10)}-${ninetyDaysAgoLocaleString.slice(3, 5)}-${ninetyDaysAgoLocaleString.slice(0, 2)}`,
  )

  const dataMeta = computed(() => {
    const localItemsCount = refIndicateListTable.value
      ? refIndicateListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalIndicates.value,
    }
  })

  const refetchData = () => {
    refIndicateListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchCampaigns = () => {
    useJwt
      .listCampaing()
      .then(response => {
        const campaignsLista = response.data.filter(
          campaing => campaing.status !== 'ARQUIVADA',
        )
        const listaDeCampanhas = []
        // eslint-disable-next-line no-unused-vars
        const campaignsListaName = campaignsLista.map(c => listaDeCampanhas.push({ label: c.name, value: c.name }))

        campaignsList.value = listaDeCampanhas
        return response
      })
      .catch(error => error)
  }

  const invertDateOrder = date => {
    const invertedDate = `${date.slice(6, 10)}-${date.slice(0, 2)}-${date.slice(
      3,
      5,
    )}`
    return invertedDate
  }

  const fetchIndicates = (ctx, callback) => {
    let payload = {
      Keyword: searchQuery.value,
      PageSize: perPage.value,
      PageNumber: currentPage.value - 1,
      PropertyName: sortBy.value,
      sortBy: isSortDirDesc.value,
      Stage: stageFilter.value,
      Status: statusFilter.value,
      filterDatesFilter: filterDatesFilter.value,
      CampaignName: campaignsFilter.value,
    }
    if (!isSingleDate.value) {
      payload = {
        ...payload,
        InitDate:
          periodDateFilter.value === null || periodDateFilter.value === ''
            ? `${initDate.value} T00:00:00.0000001`
            : `${invertDateOrder(
              periodDateFilter.value.slice(0, 10),
            )} T00:00:00.0000001`,
        EndDate:
          periodDateFilter.value === null || periodDateFilter.value === ''
            ? `${endDate.value} T23:59:59.9999999`
            : `${invertDateOrder(
              periodDateFilter.value.slice(15, 25),
            )} T23:59:59.9999999`,
      }
    } else {
      payload = {
        ...payload,
        InitDate:
          singleDateFilter.value === null || singleDateFilter.value === ''
            ? `${initDate.value} T00:00:00.0000001`
            : `${invertDateOrder(singleDateFilter.value)} T00:00:00.0000001`,
        EndDate:
          singleDateFilter.value === null || singleDateFilter.value === ''
            ? `${endDate.value} T23:59:59.9999999`
            : `${invertDateOrder(singleDateFilter.value)} T23:59:59.9999999`,
      }
    }

    store
      .dispatch('app-indicate/fetchIndicates', payload)
      .then(response => {
        // const { indicates, total } = response.data
        const indicates = response.data.objectLists[0]
        // console.log('indicações: ', indicates)
        const total = response.data.totalResults

        callback(indicates)
        totalIndicates.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching indicates list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchDetailsCoupon = coupon => {
    const payload = coupon
    store
      .dispatch('app-indicate/fetchCouponDetails', {
        payload,
      })
      .then(response => response)
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro',
            text: 'Não foi possível carregar informações deste cupom.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveIconInHistoryCoupon = stage => {
    if (stage === 'Em andamento') return 'CheckIcon'
    if (stage === 'Cancelado') return 'XIcon'
    if (stage === 'Concluído') return 'GiftIcon'
    return 'ClockIcon'
  }

  const resolveIndicateStageIcon = stage => {
    if (stage === 'Em Andamento') return 'ClockIcon'
    if (stage === 'Cancelado') return 'XIcon'
    if (stage === 'Concluído') return 'CheckIcon'
    return 'ClockIcon'
  }
  const resolveIndicateStageVariant = stage => {
    if (stage === 'Em Andamento' || stage === 'Em andamento') return 'dark'
    if (stage === 'Cancelado') return 'danger'
    if (stage === 'Concluído' || stage === 'Indicação confirmada') return 'success'
    return 'dark'
  }

  const resolveIndicateStatusVariant = status => {
    if (status === 'Cupom ativado') return 'primary'
    if (status === 'Cupom validado') return 'warning'
    if (status === 'Cupom gerado') return 'warning'
    if (status === 'Indicação confirmada') return 'success'
    if (status === 'Cupom expirado') return 'danger'
    if (status === 'Indicação cancelada') return 'danger'
    return 'dark'
  }

  return {
    fetchIndicates,
    fetchCampaigns,
    fetchDetailsCoupon,
    tableColumns,
    perPage,
    currentPage,
    totalIndicates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refIndicateListTable,
    currentCouponDetailsToken,

    resolveIndicateStageIcon,
    resolveIndicateStageVariant,
    resolveIndicateStatusVariant,
    resolveIconInHistoryCoupon,
    refetchData,

    // Extra Filters
    statusFilter,
    stageFilter,
    campaignsFilter,
    filterDatesFilter,
    periodDateFilter,
    singleDateFilter,
    isSingleDate,

    campaignsList,
  }
}
